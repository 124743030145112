

import { useState } from 'react';
import { Icon } from '@/components/icon';
import { LineLayout } from '@/layout'
import { Popover, Icon as IconAntd, Tabs, WhiteSpace, Badge, Button } from 'antd-mobile';
import classNames from 'classnames';
import { Card } from '@/components/card';
import { getTimeRangeByUrl, timeRangeTypeMap } from '@/utils/time';
import { useHistory } from 'react-router-dom'
import './index.scss';
import { useMount } from 'ahooks';
import { title } from 'process';
import { Line } from '../thumbnail';
import { DatePicker } from '@/components/datepicker';
import moment from 'moment';

interface PageBarProps {
  title: string,
  hiddenIcon?: boolean,
}

const PageBar = (props: PageBarProps) => {
  const history = useHistory()
  const clickBack = () => {
    history.goBack()
  }
  const clickToHome = () => {
    history.push('/')
  }
  return <div className='page-bar'>
    <div className='page-bar-content'>
      {
        props.hiddenIcon ? null : <div onClick={clickBack}>
          <Icon iconName='goback.png' />
        </div>
      }
      <div className='bar-value'>{props.title}</div>

      {
        props.hiddenIcon ? null : <div onClick={clickToHome}>
          <Icon iconName='home.png' />
        </div>
      }
    </div>

  </div>
}

interface CardBarProps {
  title: string,
  icon: string,
  size?: number,
  hideForward?: boolean,
  color?: string,
  fontWeight?: boolean,
  width?: number,
}

/**
 * 卡片标题
 * @param {string} title 标题内容 string
 * @param {string} icon 图表地址 string
 * @param {number} size 样式大小, 按比例缩放, 1为原比例 number
 * @param {boolean} hideForward 是否隐藏前进图标 boolean
 * @param {string} color 文字颜色 string
 * @param {boolean} fontWeight 文字是否加粗 boolean
 * @param {number} width 最外层宽度 number
 * @returns 
 */

const CardBar = (props: CardBarProps) => {
  return (
    <LineLayout justify='between' height={20} width={props.width === null ? 'auto' : props.width} padding={[0, 10, 0, 10]} margin={[10, 0, 10, 0]}>
      <LineLayout justify='start'>
        {
          props.icon ? <Icon iconName={props.icon} size={props.size} /> : null
        }

        <div className='bar-value card-title' style={{ zoom: props.size, color: props.color || '#f8f8f8', fontWeight: props.fontWeight ? 'bold' : 'normal' }}>{props.title}</div>
      </LineLayout>

      {
        props.hideForward ? null : <Icon iconName='rightArrow.png' size={0.6} />
      }

    </LineLayout>
  )
}

interface RegionBarProps {
  title: string,
}

/**
 * 层级标题
 * @param {string} title - 标题内容 string
 * @returns 
 */

const RegionBar = (props: RegionBarProps) => {
  return (
    <div className='bar-value region-container'>
      {props.title}
    </div>
  )
}
interface TimeBarProps {
  click?: Function,
  change?: Function,
  size?: number,
  height?: number,
  selected?: number,
}

/**
 * 时间栏
 * @param {Function} click 点击回调函数 Function
 * @param {Function} change 改变回调函数 Function
 * @param {number} size 样式大小, 1表示原比例 number
 * @param {number} height 高度 number
 * @param {number} selected 选中的时间选项编号 string
 * @returns 
 */

const TimeBar = (props: TimeBarProps) => {
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState(0);
  const [popoverContent, setPopoverContent] = useState('日期查询');
  const [beginTime, setBeginTime] = useState('')
  const [endTime, setEndTime] = useState('')
  const [isShowDateSelectePanel, setShowDateSelectePanel] = useState(false)
  const tabs = [
    { name: '今日', timeRangeType: 'today' },
    { name: '本周', timeRangeType: 'week' },
    { name: '本月', timeRangeType: 'month' },
    { name: '今年', timeRangeType: 'yearByMonth' },
  ]
  useMount(() => {
    let endTime = moment().format('YYYY-MM-DD')
    let beginTime = moment().subtract(30, 'day').format('YYYY-MM-DD')
    setEndTime(endTime)
    setBeginTime(beginTime)
  })

  const handleVisibleChange = (visible: boolean) => {
    setVisible(visible);
  };
  const handleClickChangeTab = (item: any, idx: number) => {
    if (props.change) {
      props.change(timeRangeTypeMap[item.timeRangeType], item.idx)
      setShowDateSelectePanel(false)
    }
    setSelected(idx)
    setVisible(false);
    if (item.isPopover) {
      setPopoverContent(item.name)
      setSelected(3)
    }
  }
  //点击确认日期
  const confirmSelectDate = () => {
    //做一些数据校验
    //结束日期不得早于开始日期
    //结束日期不得早于今天
    if (props.change) {
      props.change({ endTime, beginTime, timeRangeType: 2 })
    }
  }

  const showDateSelectPanel = () => {
    setShowDateSelectePanel(true)
  }
  useMount(() => {
    if (props.change) {
      props.change(getTimeRangeByUrl())
    }
  })

  const closeDateSelectPanel = () => {
    setShowDateSelectePanel(false)
  }
  return (
    <div className='bar-value'>
      <LineLayout justify='around' height={35} padding={[0, 10, 0, 10]}>
        {
          tabs.map((item: any, idx: number) => {
            return <div onClick={() => handleClickChangeTab(item, idx)}
              className={classNames({ 'time-select': idx === selected })}
              style={{ zoom: props.size || 1 }}>{item.name}
            </div>
          })
        }
        <div onClick={() => { showDateSelectPanel() }} style={{ fontSize: '17px' }}>按日期查询</div>
      </LineLayout>
      {
        isShowDateSelectePanel ? <Card title={''} height={175} margin={[0, 0, 10, 0]} >
          <div className='time-bar-datepicker-content' >
            <div className='close-btn' onClick={closeDateSelectPanel} />
            <DatePicker defaultDate={beginTime} label='开始日期' change={(date: any) => { setBeginTime(date) }} />
            <DatePicker defaultDate={endTime} label='结束日期 ' change={(date: any) => { setEndTime(date) }} />
            <Button onClick={() => { confirmSelectDate() }} type='primary' size='small' style={{ marginTop: "15px" }}>查 询</Button>
          </div>
        </Card> : null
      }
    </div>
  )
}




const TimeBar2 = (props: any) => {
  const tabs = [
    { title: '今日', timeRangeType: 'today' },
    { title: '本周', timeRangeType: 'week' },
    { title: '本月', timeRangeType: 'month' },
    { title: <Badge text={'按周'}>今年</Badge>, timeRangeType: 'yearByWeek' },
    { title: <Badge text={'按月'}>今年</Badge>, timeRangeType: 'yearByMonth' },
    { title: <Badge text={'按季度'}>今年</Badge>, timeRangeType: 'yearByQuarter' },
    { title: '累积', timeRangeType: 'totalByYear' },
    { title: '上周', timeRangeType: 'lastWeek' },
    { title: '上月', timeRangeType: 'lastMonth' },
  ];

  return (
    <div>
      <WhiteSpace />
      <Tabs tabs={tabs} renderTabBar={props => <Tabs.DefaultTabBar {...props} page={4}
        tabBarBackgroundColor='#000'
        tabBarTextStyle={{ fontSize: '17.5px', fontWeight: 'normal' }}
        tabBarUnderlineStyle={{ borderColor: '#4992FF' }}
        tabBarActiveTextColor={'#4992FF'}
        tabBarInactiveTextColor={'#fff'}
        onTabClick={(item: any, index: number) => { console.log('已经点击', item, index) }}
      />}>
      </Tabs>
      <WhiteSpace />
    </div>
  )
}
export { PageBar, CardBar, RegionBar, TimeBar, TimeBar2 }
